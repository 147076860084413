.options {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 3em 6em 0 6em;
  font-style: italic;
}

.options input {
  padding: 0.375rem 0.75rem;
  min-width: 300px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.options input:hover {
  color: var(--azul-gac);
}

.btn-clear {
  background: var(--fondo-defecto);
  border: 1px solid var(--azul-gac);
  border-radius: 0.375rem;
  color: var(--azul-gac);
  padding: 0.375rem 0.75rem;
  margin: 0 1em;
  font-weight: 400;
  font-style: italic;
}
.btn-clear:hover {
  background: var(--rojo-gac);
  color: var(--fondo-defecto);
  border: none;
}
.btn-excel {
  background-color: #185c37;
  display: flex;
  gap: 5px;
  width: fit-content;
  height: fit-content;
  padding: 1em 2em;
  border-radius: 1em;
  border: none;
  color: var(--fondo-defecto);
  font-weight: 400;
  margin: 1.5em 0;
  box-shadow: 2px 3px 7px #ced4da;
  transition: all 0.3s ease-in;
}
.btn-excel:hover {
  transform: scale(1.1);
}

.result {
  background: var(--amarillo-gac);
  padding: 1.3em;
  width: fit-content;
  margin: 0 auto;
  border-radius: 1em;
  color: var(--azul-gac);
  box-shadow: 2px 3px 7px #ced4da;
}
.result .info {
  background: var(--azul-gac);
  color: var(--fondo-defecto);
  width: fit-content;
  font-weight: 400;
  border-radius: 0.5em;
  margin: 0;
  padding: 0.4em;
}
.result .info p {
  margin: 0 0.4em;
  border: 2px solid var(--fondo-defecto);
  border-radius: 0.3em;
  padding: 0 1em;
}

@media screen and (max-width: 1000px) {
  .options {
    display: grid;
    grid-template-columns: 1fr;
    padding: 3em 0em 0 3em;
  }
}

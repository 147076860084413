.option-nav {
  color: var(--azul-gac);
  font-weight: 700;
  text-transform: uppercase;
  display: inline-block;
  padding: 0 1em;
  display: flex;
  margin: 0 2px;
}
.nav-item button {
  border: none;
  background: transparent;
  border-right: 2px solid;
}
.nav-item .option-nav:nth-child(-n + 3) {
  border-right: 2px solid var(--azul-gac);
}
.option-nav:hover {
  text-shadow: 1px 1px var(--azul-gac);
  color: var(--azul-gac);
}
.option-nav.active {
  background: var(--azul-gac);
  color: white;
  border-radius: 0.5em;
}
.option-nav svg path {
  fill: currentcolor;
  width: 20px;
}
.dropdown-item svg path {
  fill: currentcolor;
  width: 20px;
}
#dropdown {
  border: 0;
}
svg {
  width: 30px;
  height: 25px;
  display: block;
}
.nav-link:focus,
.nav-link:hover {
  color: var(--azul-gac);
}
/* clases de bootstrap custom */
.dropdown-menu.show {
  background-color: var(--azul-gac);
  color: var(--fondo-defecto);
  border: none;
  width: 130%;
}
.dropdown-menu.show .dropdown-item {
  background-color: var(--azul-gac);
  color: var(--fondo-defecto);
}
.dropdown-item {
  display: flex;
  justify-content: flex-end;
}
.padding-container {
  padding: 0.8em 2em;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(2px);
}

.navbar-expand-lg .offcanvas .offcanvas-body {
  justify-content: center;
}

.offcanvas {
  z-index: 9999;
}

.li-info {
  display: none;
  background: var(--amarillo-gac);
  color: var(--azul-gac);
  padding: 1em;
  border-radius: 1em;
  margin: 1em;
}
.li-info p {
  margin: 0px;
}
@media only screen and (max-width: 1200px) {
  .option-nav {
    padding: 0.9em 1em;
    border-bottom: 1px solid;
  }
  .nav-item .option-nav:nth-child(-n + 3) {
    border-right: none;
  }
}
@media only screen and (max-width: 600px) {
  .li-info {
    display: block;
  }
}

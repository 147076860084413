.mainLayout {
  width: 100%;
  height: 100%;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
}
.textWelcome {
  position: absolute;
  z-index: 2;
  color: #fff;
  text-shadow: 2px 2px var(--azul-gac);
  width: 40%;
  font-size: calc(2.5vw + 10px);
  font-weight: 700;
  padding: 1em 0.5em;
}
.progress-container {
  width: 100%;
  background-color: #f0f0f0;
  position: fixed;
  top: 0;
  z-index: 9999;
}

.progress-bar {
  height: 4px;
  background-color: var(--amarillo-gac);
  animation: progressAnimation 7s linear;
}
.animate {
  animation: progressAnimation 7s linear infinite;
}

@keyframes progressAnimation {
  0% {
    width: 0%;
  }
  10% {
    width: 10%;
  }
  20% {
    width: 20%;
  }
  30% {
    width: 30%;
  }
  40% {
    width: 40%;
  }
  50% {
    width: 50%;
  }
  60% {
    width: 60%;
  }
  70% {
    width: 70%;
  }
  80% {
    width: 80%;
  }
  90% {
    width: 90%;
  }
  100% {
    width: 100%;
  }
}

.itemLayout {
  width: 200px;
  height: 300px;
  list-style-type: none;
  position: absolute;
  top: 55%;
  transform: translateY(-50%);
  z-index: 1;
  background-position: center;
  background-size: cover;
  border-radius: 20px;
  box-shadow: 0 20px 30px rgba(255, 255, 255, 0.3) inset;
  transition: transform 0.1s, left 0.75s, top 0.75s, width 0.75s, height 0.75s;

  &:nth-child(1) .categoryCard {
    display: none;
  }

  &:nth-child(1),
  &:nth-child(2) {
    left: 0;
    top: 0;
    width: 100%;
    height: 135%;
    transform: none;
    border-radius: 0;
    box-shadow: none;
    opacity: 1;
  }

  &:nth-child(3) {
    left: 50%;
  }
  &:nth-child(4) {
    left: calc(50% + 220px);
  }
  &:nth-child(5) {
    left: calc(50% + 440px);
  }
  &:nth-child(6) {
    left: calc(50% + 660px);
    opacity: 1;
    display: none;
  }
}
.categoryCard {
  position: absolute;
  bottom: 0;
  background: linear-gradient(45deg, #ffd306, red);
  height: 15%;
  width: 100%;
  color: darkblue;

  &:nth-child(1) {
    color: purple;
    background-color: #ffffff;
  }
}
.content {
  width: min(30vw, 400px);
  position: absolute;
  top: 42%;
  left: 0rem;
  transform: translateY(-50%);
  font: 400 0.85rem helvetica, sans-serif;
  color: white;
  text-shadow: 0 3px 8px rgba(0, 0, 0, 0.5);
  opacity: 0;
  display: none;
  background: linear-gradient(45deg, #003893 30%, transparent);
  width: 50%;
  padding: 0.9em 3em;
  & .titleSection {
    font-weight: 600;
    font-size: calc(3vw + 1px);
  }

  & .descriptionSection {
    line-height: 1.7;
    margin: 1rem 0;
    font-size: 0.9rem;
    font-weight: 600;
    width: 70%;
    text-align: left;
  }

  & .buttonAction {
    border-radius: 0.25rem;
    background-color: var(--amarillo-gac);
    border: none;
    color: var(--azul-gac);
    font-weight: 600;
    width: fit-content;
    padding: 0.3rem 0.95rem;
    transition: all 0.3s ease-in-out;
  }
}

.itemLayout:nth-of-type(2) .content {
  display: block;
  animation: show 0.75s ease-in-out 0.3s forwards;
}

@keyframes show {
  0% {
    filter: blur(5px);
    transform: translateY(calc(-50% + 75px));
  }
  100% {
    opacity: 1;
    filter: blur(0);
  }
}

.navOptions {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
  user-select: none;

  & svg {
    fill: #fff;
  }

  & .btnItem {
    background-color: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(1px);
    border: 3px solid #ffffff8c;
    margin: 0 0.25rem;
    padding: 8px;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    transition: all 0.3s ease-in-out;

    &:hover {
      background: rgb(0, 56, 147, 0.9);
      transform: scale(1.1);
      border: 2px solid #fff;
    }
    &:hover svg {
      height: 30px;
    }
  }
}

@media (width > 650px) and (width < 900px) {
  .textWelcome {
    top: 80px;
    width: 60%;
    text-align: center;
  }
  .content {
    & .titleSection {
      font-size: 1rem;
    }
    & .descriptionSection {
      font-size: 0.7rem;
    }
    & .buttonAction {
      font-size: 0.7rem;
    }
  }
  .itemLayout {
    width: 160px;
    height: 270px;

    &:nth-child(3) {
      left: 50%;
    }
    &:nth-child(4) {
      left: calc(50% + 170px);
    }
    &:nth-child(5) {
      left: calc(50% + 340px);
      max-width: calc(100% - 95%);
    }
    &:nth-child(6) {
      left: calc(50% + 510px);
      opacity: 0;
    }
  }
}

@media (width < 650px) {
  .textWelcome {
    top: 70px;
    width: 46%;
    text-align: center;
    font-size: calc(3.5vw + 11px);
  }
  .content {
    padding: 0.9em 0.3em;
    top: 42%;
    & .titleSection {
      font-size: 0.9rem;
    }
    & .descriptionSection {
      font-size: 0.7rem;
      width: 90%;
    }
    & .buttonAction {
      font-size: 0.7rem;
    }
  }
  .itemLayout {
    top: 57%;
    width: 130px;
    height: 220px;

    &:nth-child(3) {
      left: 47%;
    }
    &:nth-child(4) {
      left: calc(47% + 140px);
      max-width: calc(100% - 81%);
    }
    &:nth-child(5) {
      left: calc(50% + 280px);
      display: none;
    }
    &:nth-child(6) {
      left: calc(50% + 420px);
      opacity: 0;
    }
  }
  .navOptions {
    top: 77%;
  }
}
@media (width < 415px) {
  .itemLayout {
    top: 57%;
    width: 130px;
    height: 220px;

    &:nth-child(3) {
      left: 47%;
    }
    &:nth-child(4) {
      left: calc(47% + 140px);
      max-width: calc(100% - 85%);
    }
  }
}

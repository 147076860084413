.contenedor-relative{
  position: relative;
}
.title-section{
  position: absolute;
  margin-left: 9.5%;
  transform: translateY(-83%);
  font-size: 6rem;
  color: var(--fondo-defecto);
  font-weight: 700;
  text-align: center;
}

.cardPromos{
  box-shadow: 5px 6px 20px #ccc;
  border-radius: 1em;
  padding: 1em 0.5em;
}
.cardPromos h1{
  color: var(--azul-gac);
  text-align: center;
  font-weight: 700;
}
@media only screen and (max-width: 1100px){
  .title-section{
    font-size: 3rem;
  }
}
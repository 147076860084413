.btn-info {
  width: 60px;
  height: 60px;
  background: url("../../public/images/info.png") no-repeat center/contain;
  position: fixed;
  z-index: 1038;
  bottom: 12%;
  right: 5%;
  border: none;
  filter: drop-shadow(0px 0px 2px #fff);
}
.option-info {
  background-color: var(--azul-gac);
  border-radius: 1em;
  color: var(--fondo-defecto);
  font-weight: 500;
  min-width: 250px;
  width: max-content;
  height: 100px;
  margin: -140px 0 0 -194px;
  opacity: 1;
  padding: 1em;
  display: flex;
  flex-direction: column;
  gap: 0.8em;
}
.group-info {
  display: flex;
  gap: 10px;
}
.btn-call {
  background: var(--azul-gac);
  position: fixed;
  z-index: 1038;
  bottom: 20px;
  right: 90px;
  border-radius: 50%;
  fill: white;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: 0 8px 24px rgba(0, 0, 0, 0.16);
}

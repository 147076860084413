.card-change-password {
  max-width: 400px;
  margin: 8rem auto;
  height: fit-content;
  border-radius: 2rem;
  padding: 2rem;
  box-shadow: 6px 6px var(--amarillo-gac);
  background: var(--fondo-defecto);
}
.img-logo-gac {
  display: grid;
  margin: 1rem auto;
}
.msj-alert {
  background: aliceblue;
  border: 1px solid var(--azul-gac);
  padding: 0.3rem 1rem;
  border-radius: 0.5rem;
  color: var(--azul-gac);
  font-weight: 500;
  text-align: center;
  margin: 1rem 0;
  animation: scale-up-center 0.5s;
}
@keyframes scale-up-center {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}
.btn-change-password {
  border: none;
  border-radius: 2rem;
  padding: 0.8rem 1rem;
  color: var(--azul-gac);
  width: 100%;
  transition: all 0.4s;
}
.btn-change-password:hover {
  transform: scale(1.04);
}
.btn-change-password:active {
  background: var(--amarillo-gac);
}

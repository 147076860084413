.inputGac {
  font-family: "Segoe UI", sans-serif;
  margin: 2em 0;
  max-width: 100%;
  position: relative;
}

.inputGac input {
  font-size: 100%;
  padding: 0.8em;
  outline: none;
  border: 1px solid rgb(200, 200, 200);
  background-color: transparent;
  border-radius: 8px;
  width: 100%;
  color: var(--azul-gac);
}
.inputGac input::placeholder {
  color: var(--azul-gac);
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}
.inputGac label {
  font-size: 100%;
  position: absolute;
  left: 0;
  padding: 0.8em;
  margin-left: 0.5em;
  pointer-events: none;
  transition: all 0.3s ease;
  color: var(--oscuro);

  transform: translateY(-50%) scale(0.9);
  margin: 0em;
  margin-left: 1.3em;
  padding: 0.4em;
  background-color: #fff;
  color: var(--azul-gac);
  text-transform: uppercase;
  font-weight: 800;
  letter-spacing: -0.05em;
  font-size: 0.8em;
}

.inputGac :is(input:focus, input:valid) {
  border-color: var(--azul-gac);
  color: var(--azul-gac);
}

.input_container-login {
  width: 100%;
  height: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.input_label-login {
  font-size: 0.85rem;
  color: var(--oscuro);
  font-weight: 600;
}

.input_field-login {
  width: auto;
  height: 40px;
  padding: 0 0 0 40px;
  border-radius: 7px;
  outline: none;
  border: 1px solid #e5e5e5;
  filter: drop-shadow(0px 1px 0px #efefef)
    drop-shadow(0px 1px 0.5px rgba(239, 239, 239, 0.5));
  transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
}

.input_field-login:focus {
  border: 1px solid transparent;
  box-shadow: 0px 0px 0px 2px #242424;
}
.icon-login {
  width: 20px;
  position: absolute;
  z-index: 99;
  left: 12px;
  bottom: 9px;
}

.body {
  display: flex;
  flex-flow: row wrap;
  align-content: center;
  justify-content: center;
  height: 80vh;
  width: 100%;
}

.body div {
  width: 100%;
  text-align: center;
}

.number404 {
  position: relative;
  font: 900 30vmin "Consolas";
  letter-spacing: 5vmin;
  text-shadow: 2px -1px 0 #000, 4px -2px 0 #0a0a0a, 6px -3px 0 #0f0f0f,
    8px -4px 0 #141414, 10px -5px 0 #1a1a1a, 12px -6px 0 #1f1f1f,
    14px -7px 0 #242424, 16px -8px 0 #292929;
}
.number404::before {
  background-color: var(--azul-gac);
  background-image: radial-gradient(
      closest-side at 50% 50%,
      var(--amarillo-gac) 100%,
      rgba(0, 0, 0, 0)
    ),
    radial-gradient(
      closest-side at 50% 50%,
      var(--rojo-gac) 100%,
      rgba(0, 0, 0, 0)
    );
  background-repeat: repeat-x;
  background-size: 40vmin 40vmin;
  background-position: -100vmin 20vmin, 100vmin -25vmin;
  width: 100%;
  height: 100%;
  mix-blend-mode: screen;
  -webkit-animation: moving 10s linear infinite both;
  animation: moving 10s linear infinite both;
  display: block;
  position: absolute;
  content: "";
}
@-webkit-keyframes moving {
  to {
    background-position: 100vmin 20vmin, -100vmin -25vmin;
  }
}
@keyframes moving {
  to {
    background-position: 100vmin 20vmin, -100vmin -25vmin;
  }
}

.text404 {
  font-weight: 400;
  font-size: 5vmin;
  color: var(--azul-gac);
}
.text404 span {
  font-size: 10vmin;
}
.card-text {
  color: var(--oscuro);
  padding: 1em 5em;
}
.btn-return404 {
  background-color: var(--amarillo-gac);
  border-radius: 1em;
  color: var(--azul-gac);
  padding: 0.2em 4em;
  font-weight: 500;
}
.btn-return404:hover {
  background-color: var(--azul-gac);
  color: #fff;
}
